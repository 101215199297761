$primary: #da4c4e;
// $desktop: 769px;
// $tablet-enabled: false;
// $widescreen-enabled: false;
// $fullhd-enabled: false;
$section-padding: 2rem 1.5rem;
$section-padding-desktop: 2rem 1.5rem;

$radius-small: 0;
$radius: 0;
$radius-large: 0;

$title-weight: 400;

$weight-light: 400;
$weight-normal: 400;
$weight-medium: 400;
// $weight-semibold: 400;
// $weight-bold: 400;

@use '@fortawesome/fontawesome-free/scss/fontawesome';
@use '@fortawesome/fontawesome-free/scss/solid';

@import 'bulma/sass/utilities/initial-variables';

$navbar-breakpoint: $tablet;
$size-1: $size-3;
$size-2: $size-4;
// $size-3: $size-5;
// $size-4: $size-5;
// $size-5: $size-5;
// $size-6: $size-5;
// $size-7: $size-5;

// $button-active-color: $white-ter;
$button-active-border-color: $grey-lighter;

// keep pre-0.8 sized buttons + inputs
$control-border-width: 1px;
$button-border-width: $control-border-width;
$control-height: 2.25em;
$control-padding-vertical: calc(0.375em - #{$control-border-width});
$control-padding-horizontal: calc(0.625em - #{$control-border-width});
$button-padding-vertical: calc(0.375em - #{$button-border-width});
$button-padding-horizontal: 0.75em;

@import 'bulma/sass/utilities/_all';
@import 'bulma/sass/base/_all';
@import 'bulma/sass/helpers/_all';
@import 'bulma/sass/layout/hero';
@import 'bulma/sass/layout/section';
@import 'bulma/sass/layout/footer';
@import 'bulma/sass/grid/_all';
@import 'bulma/sass/form/_all';
@import 'bulma/sass/elements/container';
@import 'bulma/sass/elements/content';
@import 'bulma/sass/elements/button';
@import 'bulma/sass/elements/title';
@import 'bulma/sass/elements/box';
@import 'bulma/sass/elements/image';
@import 'bulma/sass/elements/table';
@import 'bulma/sass/elements/other';
@import 'bulma/sass/components/navbar';
@import 'bulma/sass/components/tabs';
@import 'bulma/sass/components/dropdown';
@import 'bulma/sass/components/pagination';
@import 'bulma/sass/components/panel';
@import 'bulma/sass/components/modal';



html {
    font-size: 16px;
}

@media screen and (min-width: $tablet) {
    html {
        font-size: 14px;
    }
}

.navbar-item img {
    height: 25px;
    max-height: none;
}

.navbar-dropdown {
    left: auto;
    right: 0;
}

@media screen and (min-width: $desktop + 100) {
    .navbar-dropdown {
        left: 0;
        right: auto;
    }
}

.has-text-dark-green {
    color: #119801;
}

.table-container .table {
    min-width: 100%;

    th, td {
        white-space: nowrap;
    }

    &.is-sortable th {
        cursor: pointer;

        &:hover,
        &.is-sorted {
            border-bottom-color: $text;
        }

        .icon {
            font-size: 0.8rem;
        }
    }
}

.image {
    &::before {
        content: "\f00d";
        display: block;
        position: absolute;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 1rem;
        width: 1rem;
        font-family: 'Font Awesome 5 Free';
        font-weight: 400;
        color: #ccc;
    }

    img {
        height: auto !important;
        margin: auto;
        background-color: white;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

///////
// Bulma hotfix
// https://github.com/jgthms/bulma/issues/2503
@media screen and (min-width: $tablet) {
    .navbar-item.is-hoverable:hover .navbar-dropdown {
        display: block;
    }
}

// safari fix
.pagination-previous,
.pagination-next,
.pagination-link,
.pagination-ellipsis {
    background-color: white;
}

////////
// Main layout container

.main-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;

    main {
        flex-grow: 1;
    }
}

//////////
// NAVBAR

.search {
    padding-left: 0;
}

@media screen and (min-width: $tablet) {
    .search {
        margin-left: 2rem;
    }

    .search .field {
        width: 300px;
    }
}

.navbar > .container .navbar-brand,
.container > .navbar .navbar-brand {
    margin-left: 0 !important;
}

@media screen and (min-width: $desktop) {
    .navbar > .container .navbar-brand,
    .container > .navbar .navbar-brand {
        margin-left: -0.75rem !important;
    }
}


.button.is-active {
    background-color: $background;
}

.button.is-inverted.is-outlined.is-primary {
    background: $primary;
    color: $white;
    border-color: $primary;
}

.button.is-inverted.is-outlined.is-primary.is-selected {
    background: transparent;
    color: $primary;
    border-color: $primary
}

@media screen and (min-width: $tablet) {
    .button.is-inverted.is-outlined.is-primary {
        color: $white;
        border-color: $white;
    }

    .button.is-inverted.is-outlined.is-primary.is-selected {
        background: white;
        color: $primary;
        border-color: $white;
    }
}


/////////////
// COMPONENTS

.settings {

    color: $text;

    .modal-card {
        // min-height: calc(80vh);
        height: calc(100vh - #{$modal-card-spacing});
    }
    @media screen and (min-width: $tablet) {
        .modal-card {
            height: 70vh;
        }
    }

    .fields li {
        // &:first-child {
        //     border-top: 1px solid $border;
        // }
        border-top: 1px solid $border;

        padding: .5rem;
        display: flex;

        .position {
            padding-right: 1rem;
            width: 3rem;
        }

        .name {
            flex-grow: 1;
        }

        .field {
            margin-bottom: 0;
            margin-left: .5rem;
        }
    }
}


.tab-select {
    margin-bottom: 1.5rem;
}

.list {
    .list-head {
        // position: sticky;
        // top: 0;
        // background-color: white;
        // box-shadow: 0 4px 5px white;

        // padding: 0.25rem 0;
        // margin-bottom: 1.25rem;
        margin-bottom: 1.5rem;

        // @media screen and (min-width: $tablet) {
            display: flex;

            .filters {
                flex-grow: 1;
            }
        // }

        .filters {
            & > div {
                display: inline-block;
            }

            .filter-dropdowns .dropdown-content {
                max-height: 70vh;
                overflow-y: auto;

                // override bulma, use pixels instead of fractions of em.
                // fixes blurry text when scrollable.
                box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);

                .dropdown-item {
                    padding-right: 4rem;
                }

                label.checkbox {
                    position: static;
                    width: 100%;
                }

                .count {
                    position: absolute;
                    right: 1rem;
                    opacity: 0.75;
                    // color: $grey;
                }
            }

            .filter-modal {
                .panel-heading {
                    display: flex;

                    .panel-title {
                        flex-grow: 1;
                    }
                }

                .panel.collapsed {
                    max-height: 11rem;
                    border-bottom: 1px solid #dbdbdb;
                    overflow-y: hidden;
                }
            }
        }

    }

    .pagination {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        // position: sticky;
        // bottom: 0;
        // background-color: white;
        // box-shadow: 0 -4px 5px white;
    }
}

@media screen and (min-width: 500px) {
    .tiles {
        display: flex;
        flex-wrap: wrap;
    }
}

.tiles {
    @media screen and (min-width: 500px) {
        .column {
            display: flex;
            flex: none;
            width: 50%;
        }
    }

    @media screen and (min-width: $tablet) {
        .column {
            width: 33.333%;
        }

        .lazyload-placeholder {
            height: 410px;
        }
    }

    @media screen and (min-width: $desktop) {
        .lazyload-placeholder {
            height: 383px;
        }
    }

    @media screen and (min-width: $widescreen) {
        .column {
            width: 25%;
        }

        .lazyload-placeholder {
            height: 422px;
        }
    }

    .lazyload-wrapper, .box {
        flex-grow: 1;
    }

    .box {
        a, a:hover, a:focus, a:active, a:visited {
            color: inherit;

            .description {
                min-height: 3rem;
                color: $primary;
            }
        }
    }

}

// control
.picture-key {

    .picture-key-control {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .picture-key-entries {

        & > div {
            display: flex;
            align-content: center;
            margin-top: 0.5rem;

            &:first-child {
                margin-top: 0;
            }

            & > :first-child {
                flex-basis: auto;
                flex-grow: 0;
                flex-shrink: 0;
            }

            & > :last-child {
                flex-basis: auto;
                flex-grow: 1;
                flex-shrink: 1;
            }

            & .image {
                margin-right: 1rem;
            }
        }
    }
}



////////
// PAGES

.startpage {
    .searchfield {
        max-width: 40rem;
        margin: auto;
    }
}


.detail, .settings {

    $rowgap: 0.2rem;

    .columns {
        margin-right: -0.5rem;
        margin-left: -0.5rem;
        margin-top: -$rowgap;
        margin-bottom: $rowgap;

        .column {
            padding-right: -0.5rem;
            padding-left: -0.5rem;
            padding-top: $rowgap;
            padding-bottom: $rowgap;
        }

        @media screen and (min-width: $tablet) {
            .column {
                width: 50%;
            }
        }
    }

    .group-title {
        margin-top: 0.75rem;
        // font-size: $size-4;
    }
}

@media screen and (min-width: $widescreen) {
    .detail .column.picture {
        flex: none;
        width: 40%;
    }
}

@media screen and (max-width: $tablet - 1) {
    .detail .column:first-child {
        padding-bottom: 0;
    }
    .detail .column:last-child {
        padding-top: 0;
    }
}
